export default function workflowReducer(state, action) {
  switch (action.type) {
    case 'clear_screen': {
      return {
        ...state,
        uprn: '',
        customer: '',
        accountNumber: '',
        abpAddressId: null,
        matchedAddress: null,
        addressData: null,
        occupierDetails: {},
        loading: false,
        showMap: false,
        propertyType: null,
        rejectionReason: null,
        gapStatus: null,
        notes: '',
      }
    }
    case 'set_uprn': {
      return {
        ...state,
        uprn: action.payload,
      }
    }
    case 'set_account_number': {
      return {
        ...state,
        accountNumber: action.payload,
      }
    }
    case 'get_workflow_counts': {
      return {
        ...state,
        workflowCounts: action.payload,
      };
    }
    case 'get_workflow_items': {
      return {
        ...state,
        workflowItems: action.payload,
        workflowIndex: 0,
        workflowLength: action.payload.length,
        uprn: action.payload[0]
      };
    }
    case 'search_results': {
      return {
        ...state,
        uprn: action.payload.customer.ABP_UPRN,
        accountNumber: action.payload.customer.Account_Number ?? '',
        abpAddressId: null,
        matchedAddress: null,
        customer: action.payload.customer ? action.payload.customer : null,
        occupierDetails: action.payload.occupier_details ? action.payload.occupier_details : null,
        addressData: action.payload.addresses ? action.payload.addresses : null,
        showMap: true,
        rejectionReason: action.payload.customer.Rejection_Reason ? action.payload.customer.Rejection_Reason : null,
        gapStatus: action.payload.customer.state ? action.payload.customer.state : null,
        notes: action.payload.customer.comments ?? '',
        loading: false,
      };
    }
    case 'select_workflow_tab': {
      return {
        ...state,
        workflowTab: action.payload,
        loading: true,
      };
    }
    case 'workflow_next': {
      if (state.loading || state.workflowIndex === state.workflowLength - 1) return state
      const newWorkflowIndex = state.workflowIndex + 1

      return {
        ...state,
        workflowIndex: newWorkflowIndex,
        uprn: state.workflowItems[newWorkflowIndex]
      };
    }
    case 'workflow_prev': {
      if (state.loading || state.workflowIndex === 0) return state
      const newWorkflowIndex = state.workflowIndex - 1

      return {
        ...state,
        workflowIndex: newWorkflowIndex,
        uprn: state.workflowItems[newWorkflowIndex]
      };
    }
    case 'set_gap_status': {
      return {
        ...state,
        gapStatus: action.payload,
      };
    }
    case 'submit_customer': {
      return {
        ...state,
        propertyType: null,
        rejectionReason: null,
        loading: true,
      }
    }
    case 'purchase_customer': {
      return {
        ...state,
        occupierDetails: action.payload,
      };
    }
    case 'set_notes': {
      return {
        ...state,
        notes: action.payload.slice(0, 500) ,
      };
    }
    case 'submit_notes': {
      return {
        ...state,
      };
    }
    case 'select_match_address': {
      if (state.matchedAddress === action.payload.name) {
        return {
          ...state,
          matchedAddress: null, 
          abpAddressId: null,
        }
      }
      return {
        ...state,
        matchedAddress: action.payload.name,
        abpAddressId: action.payload.id,
      };
    }
    case 'show_map': {
      return {
        ...state,
        showMap: action.payload,
      };
    }
    case 'set_rejection_reason': {
      return {
        ...state,
        rejectionReason: action.payload != "Select Reason" ? action.payload : null,
      }
    }
    case 'set_property_type': {
      return {
        ...state,
        propertyType: action.payload != "Select Type" ? action.payload : null       
      }
    }
    default: {
      console.log("Unknown action: " + action.type)
    }
  }
}
