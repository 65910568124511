import { useState, useEffect } from "react"
import request from "./request"

/**
 * @param {string} key - The product key of the current module
 * @returns {Object} An object mapping translation keys to their default or overridden values.
 * @example
 * const translations = useTranslations('gap_sites_alerts');
 */
export function useTranslations(key) {
  const [translations, setTranslations] = useState({})

  useEffect(() => {
    request(true).get(`/translate?key=${key}`).then(r => {
      setTranslations(r.data)
    }).catch(e => {
        console.error(e);
      })
  }, [key])

  return translations
}

